
  @import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@700&family=Shadows+Into+Light&display=swap');

/* body {
    background-color: #9e9e9e;
    color: #fff;
    font-size: 14px;
    line-height: 1.42857143;
    margin: 40px;
    text-align: center;
} */

body {
    background-color: #1E1E1E;
    color: #fff;
    font-size: 14px;
    line-height: 1.42857143;
    /* margin: 40px; */
    text-align: center;
}

li {
    display: inline;
    margin: 0 10px;
    color: #000;
}

a {
    color: #fff;
    text-decoration: none;
}

* {
    box-sizing: border-box;
    margin: 40 px;
    padding: 0;
}




/* form {
    width : 350px;
    position: relative;
    border-radius: 5px;
    border-style: solid;
    border-color: #ccc;
    padding: 10px;
    margin: auto;
} */

form {
    border-color: #ccc;
    border-radius: 12px;
    border-style: solid;
    margin: auto;
    padding: 10px;
    margin-top: 40px;
    position: relative;
    width: 350px;
    background-color: #20212C;
}
.welcome {
    font-family: 'Shadows Into Light', cursive;
    font-size: 40px;
    font-weight: bold;
    color: #fff;
 
}

select{
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}
input {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

button {
    width: 100%;
    background-color: #4CAF50;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 12px;
    cursor: pointer;
}

.pools {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}
label {
    /* font-family: 'Shadows Into Light'; */
    padding: 12px 12px 12px 0;
    display: inline-block;
    color : #fff;
}